<template>
  <div>
    <NavBar />
    <router-view />
    <ScrollToTopButton />
    <Footer />
  </div>
</template>

<script>
import NavBar from '../components/partials/NavBar.vue';
import Footer from '../components/partials/Footer.vue';
import ScrollToTopButton from '../components/partials/ScrollToTopButton.vue';

export default {
  components: {
    NavBar,
    Footer,
    ScrollToTopButton
  }
};
</script>