<template>
  <v-btn
    v-if="visible"
    fab
    dark
    class="scroll-to-top"
    @click="scrollToTop"
  >
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
export default {
    name: 'ScrollToTopButton',
        data() {
            return {
                visible: false
            };
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            this.$vs.notify({
                title: this.$t('navbar.scroll-top'),
                text: this.$t('navbar.scroll-top-desc'),
                color: '#ffb8b8',
                icon: 'check_box',
                position: 'bottom-center',
                time:4000,
            })
        },

        handleScroll() {
            this.visible = window.scrollY > 100;
        }
    }
};
</script>

<style scoped>
    .scroll-to-top {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #ffb8b8 !important;
        color: white;
        z-index: 1000;
        transition: opacity 0.3s ease;
    }

    .scroll-to-top:hover {
        background-color: #ff8a8a !important;
    }
</style>
